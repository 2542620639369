import axios from 'axios';

const state = {
    list:null
};

const getters = {
    
};

const actions = {

    async searchEventCampaign({rootGetters}, form) {
        const headers =  {
            Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
        }

        const response = await axios.get('campaign-search', { params: form, headers: headers})
        return response
    },
    
    async searchCampaign({rootGetters}, form) {
        const headers =  {
            Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
        }

        const response = await axios.get('campaign-search', { params: form, headers: headers})
        return response
    },


    async getCampaigns({rootGetters}, form) {
        const headers =  {
                Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
            }


        const response = await axios.get('campaign-list',{ params: form, headers: headers})
        return response
    },
    
    async getCampaignImport({rootGetters}, form) {
        const headers =  {
                Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
            }


        const response = await axios.get('campaign-list-imported',{ params: form, headers: headers})
        return response
    },

    async uploadCampaignExcel({rootGetters}, form) {
        const headers =  {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
            }
        }

        const response = await axios.post('campaign-upload-file',form, headers)
        return response
    },
    
    async createCampaign({rootGetters}, form) {
        const headers =  {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
            }
        }

        const response = await axios.post('campaign-create',form, headers)
        return response
    },
    
    async updateCampaign({rootGetters}, form) {
        const headers =  {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
            }
        }

        const response = await axios.post('campaign-update',form, headers)
        return response
    },
    
    async detailCampaign({ rootGetters}, form) {
        const headers =  {
                Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
            }
        
        const response = await axios.get('campaign-detail',{ params: form, headers: headers})
        return response
    },
    
    async deleteCampaign({ rootGetters}, form) {
        const headers =  {
                Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
            }
        
        const response = await axios.delete('campaign-delete',{ params: form, headers: headers})
        return response
    },
    
    async runCampaign({ rootGetters}, form) {
        const headers =  {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
            }
        }

        const response = await axios.post('campaign-run',form, headers)
        return response
    },

    async stopCampaign({ rootGetters}, form) {
        const headers =  {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
            }
        }

        const response = await axios.post('campaign-stop',form, headers)
        return response
    },
    
    async cleanUpCampaign({ rootGetters}, form) {
        const headers =  {
                Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
            }
        
        const response = await axios.delete('account-cleanup',{ params: form, headers: headers})
        return response
    },

};

const mutations = {
    
};

export default {
  state,
  getters,
  actions,
  mutations
};
