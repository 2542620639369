import axios from 'axios'

const state = {
    list:null
};

const getters = {
    
};

const actions = {

    async getWithdrawals({rootGetters}, form) {
        const headers =  {
                Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
            }


        const response = await axios.get('withdrawal-list',{ params: form, headers: headers})
        return response
    },

    async createWithdrawal({rootGetters}, form) {
        const headers =  {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
            }
        }

        const response = await axios.post('withdrawal-create',form, headers)
        return response
    },
    
    async updateWithdrawal({rootGetters}, form) {
        const headers =  {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
            }
        }

        const response = await axios.post('withdrawal-update',form, headers)
        return response
    },
    
    async detailWithdrawal({rootGetters}, form) {
        const headers =  {
                Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
            }
        
        const response = await axios.get('withdrawal-detail',{ params: form, headers: headers})
        return response
    },
    
    async deleteWithdrawal({rootGetters}, form) {
        const headers =  {
                Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
            }
        
        const response = await axios.delete('withdrawal-delete',{ params: form, headers: headers})
        return response
    },

    async approveWithdrawal({rootGetters}, form) {
        const headers =  {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
            }
        }
        
        const response = await axios.post('withdrawal-approve',form, headers)
        return response
    },
};

const mutations = {
    
};

export default {
  state,
  getters,
  actions,
  mutations
};
