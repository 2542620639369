import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/home',
    name: 'homepage',
    component: () => import('../views/MainView.vue'),
    children: [
      //===== uploader
      {
        path: '/brands',
        name: 'brands',
        component: () => import('../pages/master/brands/IndexView.vue'),
      },
      {
        path: '/brands/create',
        name: 'brands-create',
        component: () => import('../pages/master/brands/CreateView.vue'),
      },
      {
        path: '/brands/edit/:id',
        name: 'brands-edit',
        component: () => import('../pages/master/brands/EditView.vue'),
      },
      {
        path: '/brands/:page/:keyword',
        name: 'brands-page-keyword',
        component: () => import('../pages/master/brands/IndexView.vue'),
      },
      {
        path: '/brands/:page/',
        name: 'brands-page',
        component: () => import('../pages/master/brands/IndexView.vue'),
      },
      //===== uploader
      
      //===== industries
      {
        path: '/industries',
        name: 'industries',
        component: () => import('../pages/master/industries/IndexView.vue'),
      },
      {
        path: '/industries/create',
        name: 'industries-create',
        component: () => import('../pages/master/industries/CreateView.vue'),
      },
      {
        path: '/industries/edit/:id',
        name: 'industries-edit',
        component: () => import('../pages/master/industries/EditView.vue'),
      },
      {
        path: '/industries/:page/:keyword',
        name: 'industries-page-keyword',
        component: () => import('../pages/master/industries/IndexView.vue'),
      },
      {
        path: '/industries/:page/',
        name: 'industries-page',
        component: () => import('../pages/master/industries/IndexView.vue'),
      },
      //===== industries

      //===== withdrawals
      {
        path: '/withdrawals',
        name: 'withdrawals',
        component: () => import('../pages/master/withdrawals/IndexView.vue'),
      },
      {
        path: '/withdrawals/create',
        name: 'withdrawals-create',
        component: () => import('../pages/master/withdrawals/TopupView.vue'),
      },
      {
        path: '/withdrawals/edit/:id',
        name: 'withdrawals-edit',
        component: () => import('../pages/master/withdrawals/EditView.vue'),
      },
      {
        path: '/withdrawals/detail/:id',
        name: 'withdrawals-detail',
        component: () => import('../pages/master/withdrawals/DetailView.vue'),
      },
      {
        path: '/withdrawals/:page/:keyword',
        name: 'withdrawals-page-keyword',
        component: () => import('../pages/master/withdrawals/IndexView.vue'),
      },
      {
        path: '/withdrawals/:page/',
        name: 'withdrawals-page',
        component: () => import('../pages/master/withdrawals/IndexView.vue'),
      },
      //===== withdrawals


      //===== banks
      {
        path: '/banks',
        name: 'banks',
        component: () => import('../pages/master/banks/IndexView.vue'),
      },
      {
        path: '/banks/create',
        name: 'banks-create',
        component: () => import('../pages/master/banks/CreateView.vue'),
      },
      {
        path: '/banks/edit/:id',
        name: 'banks-edit',
        component: () => import('../pages/master/banks/EditView.vue'),
      },
      {
        path: '/banks/:page/:keyword',
        name: 'banks-page-keyword',
        component: () => import('../pages/master/banks/IndexView.vue'),
      },
      {
        path: '/banks/:page/',
        name: 'banks-page',
        component: () => import('../pages/master/banks/IndexView.vue'),
      },
      //===== banks

      //===== banners
      {
        path: '/banners',
        name: 'banners',
        component: () => import('../pages/master/banners/IndexView.vue'),
      },
      {
        path: '/banners/create',
        name: 'banners-create',
        component: () => import('../pages/master/banners/CreateView.vue'),
      },
      {
        path: '/banners/edit/:id',
        name: 'banners-edit',
        component: () => import('../pages/master/banners/EditView.vue'),
      },
      {
        path: '/banners/:page/:keyword',
        name: 'banners-page-keyword',
        component: () => import('../pages/master/banners/IndexView.vue'),
      },
      {
        path: '/banners/:page/',
        name: 'banners-page',
        component: () => import('../pages/master/banners/IndexView.vue'),
      },
      //===== banners

      //===== rewards
      {
        path: '/rewards',
        name: 'rewards',
        component: () => import('../pages/master/rewards/IndexView.vue'),
      },
      {
        path: '/rewards/create',
        name: 'rewards-create',
        component: () => import('../pages/master/rewards/CreateView.vue'),
      },
      {
        path: '/rewards/edit/:id',
        name: 'rewards-edit',
        component: () => import('../pages/master/rewards/EditView.vue'),
      },
      {
        path: '/rewards/:page/:keyword',
        name: 'rewards-page-keyword',
        component: () => import('../pages/master/rewards/IndexView.vue'),
      },
      {
        path: '/rewards/:page/',
        name: 'rewards-page',
        component: () => import('../pages/master/rewards/IndexView.vue'),
      },
      //===== rewards
      
      //===== survey
      {
        path: '/survey',
        name: 'survey',
        component: () => import('../pages/master/survey/IndexView.vue'),
      },
      {
        path: '/survey/create',
        name: 'survey-create',
        component: () => import('../pages/master/survey/CreateView.vue'),
      },
      {
        path: '/survey/edit/:id',
        name: 'survey-edit',
        component: () => import('../pages/master/survey/EditView.vue'),
      },
      {
        path: '/survey/report/:id',
        name: 'survey-report',
        component: () => import('../pages/master/survey/ReportView.vue'),
      },
      {
        path: '/survey/:page/:keyword',
        name: 'survey-page-keyword',
        component: () => import('../pages/master/survey/IndexView.vue'),
      },
      {
        path: '/survey/:page/',
        name: 'survey-page',
        component: () => import('../pages/master/survey/IndexView.vue'),
      },
      //===== survey

      //===== accounts
      {
        path: '/accounts',
        name: 'accounts',
        component: () => import('../pages/master/accounts/IndexView.vue'),
      },
      {
        path: '/accounts/create',
        name: 'accounts-create',
        component: () => import('../pages/master/accounts/CreateView.vue'),
      },
      {
        path: '/accounts/edit/:id',
        name: 'accounts-edit',
        component: () => import('../pages/master/accounts/EditView.vue'),
      },
      {
        path: '/accounts/:page/:keyword',
        name: 'accounts-page-keyword',
        component: () => import('../pages/master/accounts/IndexView.vue'),
      },
      {
        path: '/accounts/:page/',
        name: 'accounts-page',
        component: () => import('../pages/master/accounts/IndexView.vue'),
      },
      //===== accounts

      //===== campaigns
      {
        path: '/campaigns',
        name: 'campaigns',
        authorized: ['superadmins','campaign_creator'],
        component: () => import('../pages/master/campaigns/IndexView.vue'),
      },
      {
        path: '/campaigns/import',
        name: 'campaigns-import',
        authorized: ['superadmins','campaign_creator'],
        component: () => import('../pages/master/campaigns/ImportView.vue'),
      },
      {
        path: '/campaigns/create',
        name: 'campaigns-create',
        component: () => import('../pages/master/campaigns/CreateView.vue'),
      },
      {
        path: '/campaigns/create/:id',
        name: 'campaigns-clone',
        component: () => import('../pages/master/campaigns/CreateView.vue'),
      },
      {
        path: '/campaigns/edit/:id',
        name: 'campaigns-edit',
        component: () => import('../pages/master/campaigns/EditView.vue'),
      },
      {
        path: '/campaigns/report/:id',
        name: 'campaigns-report',
        component: () => import('../pages/master/campaigns/ReportView.vue'),
      },
      {
        path: '/campaigns/:page/:keyword',
        name: 'campaigns-page-keyword',
        component: () => import('../pages/master/campaigns/IndexView.vue'),
      },
      {
        path: '/campaigns/:page/',
        name: 'campaigns-page',
        component: () => import('../pages/master/campaigns/IndexView.vue'),
      },
      //===== campaigns

      //===== invoices
      {
        path: '/invoices',
        name: 'invoices',
        component: () => import('../pages/master/invoices/IndexView.vue')
      },
      {
        path: '/invoices/:id',
        name: 'invoices-detail',
        component: () => import('../pages/master/invoices/DetailView.vue')
      },
      //===== invoices

      //===== //===== rewards
      {
        path: '/rewards',
        name: 'rewards',
        component: () => import('../pages/master/rewards/IndexView.vue'),
      },
      {
        path: '/rewards/create',
        name: 'rewards-create',
        component: () => import('../pages/master/rewards/CreateView.vue'),
      },
      {
        path: '/rewards/edit/:id',
        name: 'rewards-edit',
        component: () => import('../pages/master/rewards/EditView.vue'),
      },
      {
        path: '/rewards/:page/:keyword',
        name: 'rewards-page-keyword',
        component: () => import('../pages/master/rewards/IndexView.vue'),
      },
      {
        path: '/rewards/:page/',
        name: 'rewards-page',
        component: () => import('../pages/master/rewards/IndexView.vue'),
      },
      //===== rewards

      //===== //===== CMS manager
      {
        path: '/cms',
        name: 'cms',
        component: () => import('../pages/master/cms/IndexView.vue'),
      },
      {
        path: '/cms/create',
        name: 'cms-create',
        component: () => import('../pages/master/cms/CreateView.vue'),
      },
      {
        path: '/cms/edit/:id',
        name: 'cms-edit',
        component: () => import('../pages/master/cms/EditView.vue'),
      },
      //===== //===== CMS manager

      //===== //===== microsite manager
      {
        path: '/microsites',
        name: 'microsites',
        component: () => import('../pages/master/microsites/IndexView.vue'),
      },
      {
        path: '/microsites/create',
        name: 'microsites-create',
        component: () => import('../pages/master/microsites/CreateView.vue'),
      },
      {
        path: '/microsites/edit/:id',
        name: 'microsites-edit',
        component: () => import('../pages/master/microsites/EditView.vue'),
      },
      {
        path: '/microsites/manage/:id',
        name: 'microsites-manage',
        component: () => import('../pages/master/microsites/ManageView.vue'),
      },
      {
        path: '/microsites/report/:id',
        name: 'microsites-report',
        component: () => import('../pages/master/microsites/MicrositeReport.vue'),
      },
      {
        path: '/microsites/:page/:keyword',
        name: 'microsites-page-keyword',
        component: () => import('../pages/master/microsites/IndexView.vue'),
      },
      {
        path: '/microsites/:page/',
        name: 'microsites-page',
        component: () => import('../pages/master/microsites/IndexView.vue'),
      },
      //===== microsite manager

      //===== //===== push notification
      {
        path: '/pushnotification',
        name: 'pushnotification',
        component: () => import('../pages/master/pushnotification/IndexView.vue'),
      },
      {
        path: '/pushnotification/create',
        name: 'pushnotification-create',
        component: () => import('../pages/master/pushnotification/CreateView.vue'),
      },
      //===== push notification

      //===== integrations
      {
        path: '/integrations',
        name: 'integrations',
        component: () => import('../pages/master/integrations/IndexView.vue'),
      },
      {
        path: '/integrations/create',
        name: 'integrations-create',
        component: () => import('../pages/master/integrations/CreateView.vue'),
      },
      {
        path: '/integrations/edit/:id',
        name: 'integrations-edit',
        component: () => import('../pages/master/integrations/EditView.vue'),
      },
      {
        path: '/integrations/:page/:keyword',
        name: 'integrations-page-keyword',
        component: () => import('../pages/master/integrations/IndexView.vue'),
      },
      {
        path: '/integrations/:page/',
        name: 'integrations-page',
        component: () => import('../pages/master/integrations/IndexView.vue'),
      },
      {
        path: '/integrations/logs/:id',
        name: 'integrations-logs',
        component: () => import('../pages/master/integrations/LogView.vue'),
      },
      //===== integrations

      {
        path: '/profile',
        name: 'profile',
        component: () => import('../pages/user/profileView.vue'),
      },
      {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('../pages/SuperDashboardView.vue'),
      },
      {
        path: '/super-dashboard',
        name: 'super-dashboard',
        component: () => import('../pages/SuperDashboardView.vue'),
      },
      {
        path: '/setting',
        name: 'setting',
        component: () => import('../pages/SettingView.vue'),
      },

      //===== underconstruction

      {
        path: '/:any',
        name: 'underconstruction',
        component: () => import('../pages/master/landing/UnderConstruction.vue'),
      },


      //===== underconstruction

    ]
  },

  {
    path: '/',
    name: 'login',
    component: () => import('../views/LoginView.vue')
  },
  {
    path: '/login',
    name: 'login-juga',
    component: () => import('../views/LoginView.vue')
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('../views/LogoutView.vue')
  }
]


const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
