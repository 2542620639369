import axios from 'axios';

const state = {
    list:null
};

const getters = {
    
};

const actions = {
    
    async getFields({rootGetters}, form) {
        const headers =  {
                Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
        }

        const response = await axios.get('microsite-field-list',{ params: form, headers: headers})
        return response
    },
    
    async createField({rootGetters}, form) {
        const headers =  {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
            }
        }

        const response = await axios.post('microsite-field-create',form, headers)
        return response
    },
    
    async deleteField({rootGetters}, form) {
        const headers =  {
                Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
            }
        
        const response = await axios.delete('microsite-field-delete',{ params: form, headers: headers})
        return response
    },

};

const mutations = {
    
};

export default {
  state,
  getters,
  actions,
  mutations
};
