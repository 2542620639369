import axios from 'axios';

const state = {
    list:null
};

const getters = {
    
};

const actions = {
    async searchAccount({rootGetters}, form) {
        const headers =  {
            Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
        }

        const response = await axios.get('account-search', { params: form, headers: headers})
        return response
    },

    async getAccountCount({rootGetters}, form) {
        const headers =  {
            Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
        }


        const response = await axios.get('account-count',{ params: form, headers: headers})
        return response
    },


    async getAccounts({rootGetters}, form) {
        const headers =  {
                Authorization: rootGetters.userToken ? `Bearer ${rootGetters.userToken}` : '',
            }


        const response = await axios.get('account-list',{ params: form, headers: headers})
        return response
    },

    async createAccount({rootGetters}, form) {
        const headers =  {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: rootGetters.userToken ? `Bearer ${rootGetters.userToken}` : '',
            }
        }

        const response = await axios.post('account-create',form, headers)
        return response
    },
    
    async updateAccount({rootGetters}, form) {
        const headers =  {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: rootGetters.userToken ? `Bearer ${rootGetters.userToken}` : '',
            }
        }

        const response = await axios.post('account-update',form, headers)
        return response
    },
    
    async detailAccount({rootGetters}, form) {
        const headers =  {
                Authorization: rootGetters.userToken ? `Bearer ${rootGetters.userToken}` : '',
            }
        
        const response = await axios.get('account-detail',{ params: form, headers: headers})
        return response
    },
    
    async deleteAccount({rootGetters}, form) {
        const headers =  {
                Authorization: rootGetters.userToken ? `Bearer ${rootGetters.userToken}` : '',
            }
        
        const response = await axios.delete('account-delete',{ params: form, headers: headers})
        return response
    },
    
    async cleanUpAccount({rootGetters}, form) {
        const headers =  {
                Authorization: rootGetters.userToken ? `Bearer ${rootGetters.userToken}` : '',
            }
        
        const response = await axios.delete('account-cleanup',{ params: form, headers: headers})
        return response
    },

};

const mutations = {
    
};

export default {
  state,
  getters,
  actions,
  mutations
};
