import axios from 'axios';

const state = {
    list:null
};

const getters = {
    
};

const actions = {
    
    async searchIndustries({rootGetters}, form) {
        const headers =  {
            Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
        }

        const response = await axios.get('industry-search', { params: form, headers: headers})
        return response
    },

    async getIndustries({rootGetters}, form) {
        const headers =  {
            Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
        }


        const response = await axios.get('industry-list',{ params: form, headers: headers})
        return response
    },

    async createIndustry({rootGetters}, form) {
        const headers =  {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
            }
        }

        const response = await axios.post('industry-create',form, headers)
        return response
    },
    
    async updateIndustry({rootGetters}, form) {
        const headers =  {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
            }
        }

        const response = await axios.post('industry-update',form, headers)
        return response
    },
    
    async detailIndustry({rootGetters}, form) {
        const headers =  {
            Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
        }
        
        const response = await axios.get('industry-detail',{ params: form, headers: headers})
        return response
    },
    
    async deleteIndustry({rootGetters}, form) {
        const headers =  {
            Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
        }
        
        const response = await axios.delete('industry-delete',{ params: form, headers: headers})
        return response
    },

};

const mutations = {
    
};

export default {
  state,
  getters,
  actions,
  mutations
};
