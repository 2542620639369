import axios from 'axios';

const state = {
    list:null
};

const getters = {
    
};

const actions = {
    async getTokenLogs({rootGetters}, form) {
        const headers =  {
                Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
            }

        console.log(headers,"TOKEN")

        const response = await axios.get('token-list',{ params: form, headers: headers})
        return response
    },
};

const mutations = {
    
};

export default {
  state,
  getters,
  actions,
  mutations
};
