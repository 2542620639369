//store/modules/auth.js

import axios from 'axios';

const state = {
    list:null
};

const getters = {
    
};

const actions = {

    async searchUser({rootGetters}, form) {
        const headers =  {
            headers: {
                Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
            }
        }

        const response = await axios.post('user-search',form, headers)
        return response
    },
    
};

const mutations = {
    
};

export default {
  state,
  getters,
  actions,
  mutations
};
