import axios from 'axios';

const state = {
    
};

const getters = {
    
};

const actions = {
    async searchDepartment({rootGetters}, form) {
        const headers =  {
            Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
        }

        console.log(headers,"TOKEN")

        const response = await axios.get('department-list',{ params: form, headers: headers})
        return response
    },


    async deleteAppointment({rootGetters}, form) {
        const headers =  {
            Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
        }
        
        const response = await axios.delete('appointment-delete',{ params: form, headers: headers})
        return response
    },
};

const mutations = {

};

export default {
  state,
  getters,
  actions,
  mutations
};
