

import { createStore } from 'vuex'

import createPersistedState from "vuex-persistedstate";
import auth from './modules/auth';
import brand from './modules/brand';
import industry from './modules/industry';
import bank from './modules/bank';
import xendit from './modules/xendit';
import account from './modules/account';
import wilayah from './modules/wilayah';
import banner from './modules/banners';
import withdrawal from './modules/withdrawal';
import department from './modules/department';
import dashboard from './modules/dashboard';
import campaign from './modules/campaign';
import reward from './modules/reward';
import survey from './modules/survey';
import job from './modules/job';
import categories from './modules/categories';
import setting from './modules/setting';
import integration from './modules/integration';
import microsite from './modules/microsite';
import micrositefields from './modules/micrositefields';
import pushnotification from './modules/pushnotification';
import cms from './modules/cms';

import invoice from './modules/invoice';
import product from './modules/product';
import user from './modules/user';
import token from './modules/token';


import location from './modules/location';

export default createStore({
  modules: {
    auth,
    brand,
    industry,
    withdrawal,
    bank,
    xendit,
    banner,
    account,
    wilayah,
    job,
    department,
    dashboard,
    campaign,
    reward,
    survey,
    categories,
    setting,
    integration,
    microsite,
    micrositefields,
    pushnotification,
    cms,
    
    invoice,
    product,
    user,
    token,
    location,
  },
  plugins: [createPersistedState()]
});
