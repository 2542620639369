
import axios from 'axios';

const state = {
    list:null
};

const getters = {
    
};

const actions = {
    
    async getCoordinateByAddress({}, form) {
        const response = await axios.get('https://maps.googleapis.com/maps/api/geocode/json?address='+form.address+'&key=AIzaSyCHBtx96V3XiITY2KKoJVxTKe1xsm5Ifhk')
        return response
    },
};

const mutations = {
    
};

export default {
  state,
  getters,
  actions,
  mutations
};
